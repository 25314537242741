import React from "react"

import { Heading, List, ListItem } from "@kiwicom/orbit-components"
import { Check } from "@kiwicom/orbit-components/icons"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => {
  return (
    <DriveLayout title="Cycle to Work">
      <Container>
        <Hero
          title="Cycle to Work"
          subtitle="How to get a bike through the Cycle to Work scheme"
        />
        <EmberCard>
          <EmberCardSection>
            <Stack>
              <Heading type="title3">Why Cycle to Work</Heading>
              <List>
                <ListItem icon={<Check />}>
                  A healthier you - both in mind and body
                </ListItem>
                <ListItem icon={<Check />}>
                  Reaching your fitness goals more quickly
                </ListItem>
                <ListItem icon={<Check />}>
                  A happier you as you become healthier and fitter
                </ListItem>
                <ListItem icon={<Check />}>
                  Enjoying your commute to work so you arrive on time and
                  feeling energised
                </ListItem>
                <ListItem icon={<Check />}>
                  Becoming more effective in your work
                </ListItem>
                <ListItem icon={<Check />}>
                  Saving money on fuel and car parking costs
                </ListItem>
                <ListItem icon={<Check />}>
                  More environmentally friendly
                </ListItem>
              </List>
              <Heading type="title3">How the Scheme Works</Heading>
              <Text>
                The provider we've generally been using is Green Commute
                Initiative since they have the lowest fees etc. They have a{" "}
                <TextLink href="https://www.greencommuteinitiative.uk/employees/">
                  guide to the scheme
                </TextLink>
                . It's important for you to understand that the Government's
                Cycle to Work scheme is based on bicycle hire with no automatic
                right to ownership at the end of the hire period. You will not
                own the bike during the hire period.
              </Text>
              <Heading type="title3">Choosing Your Bike Package</Heading>
              <Text>
                The first step is to choose your new bike and any accessories.
                This is your "bike package". This will include your bike and can
                include any safety equipment, clothing, tools and spares. HRMC
                do not allow you to claim for GPS or bike computers.
              </Text>
              <Text>
                You can get a quote for packages from any bike shop{" "}
                <TextLink href="https://www.greencommuteinitiative.uk/employees/">
                  registered with the Green Commute Initiative
                </TextLink>{" "}
                .
              </Text>
              <Heading type="title3">Application</Heading>
              <Text>
                Once you have a quote, you need to submit an Instant GCI
                application to GCI on their website. A pro-forma invoice will be
                emailed to you. This is for a GCI voucher to pay for the
                package. This should be sent through to ops asap for us to make
                a payment to CGI for the amount.
              </Text>
              <Text>
                Once approved GCI will confirm your order with the bike shop and
                send you documents for electronic signature (hire agreement and
                salary sacrifice). You'll need to sign both in order for your
                application to be processed.
              </Text>
              <Heading type="title3">
                Paying for the Bike (Salary Sacrifice)
              </Heading>
              <Text>
                Once your application is approved, the company pay GCI payments
                for the bike. Your bike payments are then automatically deducted
                from your pre-tax pay in instalments. Typically as 12 monthly
                instalments. There's no interest on this. If you were to leave
                Ember within 12 months then you'd still be liable for the rest
                of the payments which would have to come out of pay after tax -
                i.e. as if you'd bought the bike yourself.
              </Text>
              <Heading type="title3">Owning the Bike</Heading>
              <Text>
                At the end of your loan period, you can make a £1 payment to
                take ownership of the bike.
              </Text>
              <Heading type="title3">Using Other Companies</Heading>
              <Text>
                There are some other cycle to work schemes available and we can
                generally work with them if you have your eye on a particular
                bike that's not available through a Green Commute Initiative
                shop.
              </Text>
              <Heading type="title3">Eligibility</Heading>
              <Text>
                All PT and FT drivers are eligible for this scheme upon passing
                their probation with Ember.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Container>
    </DriveLayout>
  )
}

export default Page
